import React from "react";
import { createRoot } from "react-dom/client";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import "./index.less";
import * as Sentry from "@sentry/react";
import { getConfig } from "globalFunctions";
import App from "./App";
import StateProvider from "./StateProvider";
import reducer, { initialState } from "./reducers";

Sentry.init({
  dsn: getConfig().sentry,
  integrations: [
    Sentry.replayIntegration(),
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.thirdPartyErrorFilterIntegration({
      filterKeys: ["hflhss"],
      behaviour: "drop-error-if-contains-third-party-frames",
    }),
  ],
  tracesSampleRate: 0.1,
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 1.0,
  tunnel: "/tunnel",
  release: `timandre@${process.env.PACKAGE_VERSION}`,
});

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);

const container = document.getElementById("app");
const root = createRoot(container!);

const router = sentryCreateBrowserRouter([
  {
    path: "*",
    element: <App />,
  },
]);

root.render(
  <Sentry.ErrorBoundary>
    <StateProvider reducer={reducer} initState={initialState}>
      <RouterProvider router={router} />
    </StateProvider>
  </Sentry.ErrorBoundary>
);
